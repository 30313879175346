var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pointRankList"},[_c('div',{staticClass:"box_top"},[_c('div',{staticClass:"in_box"},[_c('div',{staticClass:"tabs"},[_c('div',{class:_vm.flag === '1' ? 'Select1' : 'personal',on:{"click":function($event){return _vm.personal('1')}}},[_vm._v(" 个人积分 ")]),_c('div',{class:_vm.flag === '2' ? 'Select2' : 'community',on:{"click":function($event){return _vm.community('2')}}},[_vm._v(" 社群积分 ")])])])]),(this.flag === '1')?_c('div',{staticClass:"pointRankList-b"},[_c('div',{ref:"scollElement",staticClass:"body"},[_c('div',{staticClass:"topRank"},[_c('div',{staticClass:"item second"},[_c('div',{staticClass:"item-t"},[_c('img',{attrs:{"src":_vm.listData[1] && _vm.listData[1].avatar
                  ? _vm.listData[1].avatar
                  : require('@/assets/img/default_avatar.png'),"alt":""}})]),_c('div',{staticClass:"item-c"},[_c('div',{staticClass:"item-cl"},[_vm._v("2")]),_c('div',{staticClass:"item-cr"},[_c('span',[_vm._v(" "+_vm._s(_vm.listData[1] && _vm.listData[1].userName ? _vm.listData[1].userName : "暂无")+" ")])])]),_c('div',{staticClass:"item-b"},[_c('span',[_vm._v(" 积分:"+_vm._s(_vm.listData[1] && _vm.listData[1].totlePointCount ? _vm.listData[1].totlePointCount : "0")+" ")])])]),_c('div',{staticClass:"item te"},[_c('div',{staticClass:"item-t"},[_c('img',{attrs:{"src":_vm.listData[0] && _vm.listData[0].avatar
                  ? _vm.listData[0].avatar
                  : require('@/assets/img/default_avatar.png'),"alt":""}})]),_c('div',{staticClass:"item-c"},[_c('div',{staticClass:"item-cl"},[_vm._v("1")]),_c('div',{staticClass:"item-cr"},[_c('span',[_vm._v(" "+_vm._s(_vm.listData[0] && _vm.listData[0].userName ? _vm.listData[0].userName : "暂无")+" ")])])]),_c('div',{staticClass:"item-b"},[_c('span',[_vm._v(" 积分:"+_vm._s(_vm.listData[0] && _vm.listData[0].totlePointCount ? _vm.listData[0].totlePointCount : "0")+" ")])])]),_c('div',{staticClass:"item third"},[_c('div',{staticClass:"item-t"},[_c('img',{attrs:{"src":_vm.listData[2] && _vm.listData[2].avatar
                  ? _vm.listData[2].avatar
                  : require('@/assets/img/default_avatar.png'),"alt":""}})]),_c('div',{staticClass:"item-c"},[_c('div',{staticClass:"item-cl"},[_vm._v("3")]),_c('div',{staticClass:"item-cr"},[_c('span',[_vm._v(" "+_vm._s(_vm.listData[2] && _vm.listData[2].userName ? _vm.listData[2].userName : "暂无")+" ")])])]),_c('div',{staticClass:"item-b"},[_c('span',[_vm._v(" 积分:"+_vm._s(_vm.listData[2] && _vm.listData[2].totlePointCount ? _vm.listData[2].totlePointCount : "0")+" ")])])])]),_c('div',{staticClass:"list"},[_c('v-list',{ref:"list",attrs:{"finished":_vm.finished,"finishedText":_vm.finishedText},on:{"onLoad":_vm.onLoad}},_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"item"},[(index != 0 && index != 1 && index != 2)?_c('div',{staticClass:"oItem"},[_c('div',{staticClass:"item-l"},[_c('div',{staticClass:"indexs"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":item.avatar
                        ? item.avatar
                        : require('@/assets/img/default_avatar.png'),"alt":""}})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.userName))])]),_c('div',{staticClass:"item-r"},[_c('div',{staticClass:"pointNum"},[_vm._v(_vm._s(item.totlePointCount)+"分")]),_c('div',{staticClass:"pointImg"},[_c('img',{attrs:{"src":require("./img/point.png"),"alt":""}})])])]):_vm._e()])}),0)],1)])]):_c('div',{staticClass:"pointRankList-b"},[_c('div',{ref:"scollElement",staticClass:"body"},[_c('div',{staticClass:"topRank"},[_c('div',{staticClass:"item second"},[_c('div',{staticClass:"item-t"},[_c('img',{attrs:{"src":_vm.listData2[1] && _vm.listData2[1].picture
                  ? _vm.listData2[1].picture
                  : require('@/assets/img/default_avatar.png'),"alt":""}})]),_c('div',{staticClass:"item-c"},[_c('div',{staticClass:"item-cl"},[_vm._v("2")]),_c('div',{staticClass:"item-cr"},[_c('span',[_vm._v(" "+_vm._s(_vm.listData2[1] && _vm.listData2[1].associationName ? _vm.listData2[1].associationName : "暂无")+" ")])])]),_c('div',{staticClass:"item-b"},[_c('span',[_vm._v(" 积分:"+_vm._s(_vm.listData2[1] && _vm.listData2[1].totalPointCount ? _vm.listData2[1].totalPointCount : "0")+" ")])])]),_c('div',{staticClass:"item te"},[_c('div',{staticClass:"item-t"},[_c('img',{attrs:{"src":_vm.listData2[0] && _vm.listData2[0].picture
                  ? _vm.listData2[0].picture
                  : require('@/assets/img/default_avatar.png'),"alt":""}})]),_c('div',{staticClass:"item-c"},[_c('div',{staticClass:"item-cl"},[_vm._v("1")]),_c('div',{staticClass:"item-cr"},[_c('span',[_vm._v(" "+_vm._s(_vm.listData2[0] && _vm.listData2[0].associationName ? _vm.listData2[0].associationName : "暂无")+" ")])])]),_c('div',{staticClass:"item-b"},[_c('span',[_vm._v(" 积分:"+_vm._s(_vm.listData2[0] && _vm.listData2[0].totalPointCount ? _vm.listData2[0].totalPointCount : "0")+" ")])])]),_c('div',{staticClass:"item third"},[_c('div',{staticClass:"item-t"},[_c('img',{attrs:{"src":_vm.listData2[2] && _vm.listData2[2].picture
                  ? _vm.listData2[2].picture
                  : require('@/assets/img/default_avatar.png'),"alt":""}})]),_c('div',{staticClass:"item-c"},[_c('div',{staticClass:"item-cl"},[_vm._v("3")]),_c('div',{staticClass:"item-cr"},[_c('span',[_vm._v(" "+_vm._s(_vm.listData2[2] && _vm.listData2[2].associationName ? _vm.listData2[2].associationName : "暂无")+" ")])])]),_c('div',{staticClass:"item-b"},[_c('span',[_vm._v(" 积分:"+_vm._s(_vm.listData2[2] && _vm.listData2[2].totalPointCount ? _vm.listData2[2].totalPointCount : "0")+" ")])])])]),_c('div',{staticClass:"list"},[_c('v-list',{ref:"list2",attrs:{"finished":_vm.finished2,"finishedText":_vm.finishedText2},on:{"onLoad":_vm.onLoad2}},_vm._l((_vm.listData2),function(item,index){return _c('div',{key:index,staticClass:"item"},[(index != 0 && index != 1 && index != 2)?_c('div',{staticClass:"oItem"},[_c('div',{staticClass:"item-l"},[_c('div',{staticClass:"indexs"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":item.picture
                        ? item.picture
                        : require('@/assets/img/default_avatar.png'),"alt":""}})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.associationName))])]),_c('div',{staticClass:"item-r"},[_c('div',{staticClass:"pointNum"},[_vm._v(_vm._s(item.totalPointCount)+"分")]),_c('div',{staticClass:"pointImg"},[_c('img',{attrs:{"src":require("./img/point.png"),"alt":""}})])])]):_vm._e()])}),0)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }