<template>
  <div class="pointRankList">
    <!-- <count-to
      :start-val="0"
      :end-val="pointsData.totlePointCount"
      :duration="1000"
      class="card-panel-num"
    /> -->
    <!-- <div class="pointRankList-t">
      <div class="body">
        <div class="rank">
          <div class="rank-t">{{ pointsData.ranking }}</div>
          <div class="rank-b">我的排名</div>
        </div>
        <div class="head">
          <div class="head-l">
            <img
              :src="
                pointsData.avatar
                  ? pointsData.avatar
                  : require('@/assets/img/default_avatar.png')
              "
              alt=""
            />
          </div>
          <div class="head-r">{{ pointsData.userName }}</div>
        </div>
        <div class="point">
          <div class="point-l">{{ pointsData.totlePointCount }}分</div>
          <div class="point-r">
            <img src="./img/point.png" alt="" />
          </div>
        </div>
      </div>
    </div> -->
    <div class="box_top">
      <div class="in_box">
        <div class="tabs">
          <div
            :class="flag === '1' ? 'Select1' : 'personal'"
            @click="personal('1')"
          >
            个人积分
          </div>
          <div
            :class="flag === '2' ? 'Select2' : 'community'"
            @click="community('2')"
          >
            社群积分
          </div>
        </div>
      </div>
    </div>
    <!-- 个人 -->
    <div class="pointRankList-b" v-if="this.flag === '1'">
      <div class="body" ref="scollElement">
        <div class="topRank">
          <div class="item second">
            <div class="item-t">
              <img
                :src="
                  listData[1] && listData[1].avatar
                    ? listData[1].avatar
                    : require('@/assets/img/default_avatar.png')
                "
                alt=""
              />
            </div>
            <div class="item-c">
              <div class="item-cl">2</div>
              <div class="item-cr">
                <span>
                  {{
                    listData[1] && listData[1].userName
                      ? listData[1].userName
                      : "暂无"
                  }}
                </span>
              </div>
            </div>
            <div class="item-b">
              <span>
                积分:{{
                  listData[1] && listData[1].totlePointCount
                    ? listData[1].totlePointCount
                    : "0"
                }}
              </span>
            </div>
          </div>
          <div class="item te">
            <div class="item-t">
              <img
                :src="
                  listData[0] && listData[0].avatar
                    ? listData[0].avatar
                    : require('@/assets/img/default_avatar.png')
                "
                alt=""
              />
            </div>
            <div class="item-c">
              <div class="item-cl">1</div>
              <div class="item-cr">
                <span>
                  {{
                    listData[0] && listData[0].userName
                      ? listData[0].userName
                      : "暂无"
                  }}
                </span>
              </div>
            </div>
            <div class="item-b">
              <span>
                积分:{{
                  listData[0] && listData[0].totlePointCount
                    ? listData[0].totlePointCount
                    : "0"
                }}
              </span>
            </div>
          </div>
          <div class="item third">
            <div class="item-t">
              <img
                :src="
                  listData[2] && listData[2].avatar
                    ? listData[2].avatar
                    : require('@/assets/img/default_avatar.png')
                "
                alt=""
              />
            </div>
            <div class="item-c">
              <div class="item-cl">3</div>
              <div class="item-cr">
                <span>
                  {{
                    listData[2] && listData[2].userName
                      ? listData[2].userName
                      : "暂无"
                  }}
                </span>
              </div>
            </div>
            <div class="item-b">
              <span>
                积分:{{
                  listData[2] && listData[2].totlePointCount
                    ? listData[2].totlePointCount
                    : "0"
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="list">
          <v-list
            :finished="finished"
            @onLoad="onLoad"
            :finishedText="finishedText"
            ref="list"
          >
            <div class="item" v-for="(item, index) in listData" :key="index">
              <div class="oItem" v-if="index != 0 && index != 1 && index != 2">
                <div class="item-l">
                  <div class="indexs">{{ index + 1 }}</div>
                  <div class="img">
                    <img
                      :src="
                        item.avatar
                          ? item.avatar
                          : require('@/assets/img/default_avatar.png')
                      "
                      alt=""
                    />
                  </div>
                  <div class="name">{{ item.userName }}</div>
                </div>
                <div class="item-r">
                  <div class="pointNum">{{ item.totlePointCount }}分</div>
                  <div class="pointImg">
                    <img src="./img/point.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </v-list>
        </div>
      </div>
    </div>
    <!-- 社群 -->
    <div class="pointRankList-b" v-else>
      <div class="body" ref="scollElement">
        <div class="topRank">
          <div class="item second">
            <div class="item-t">
              <img
                :src="
                  listData2[1] && listData2[1].picture
                    ? listData2[1].picture
                    : require('@/assets/img/default_avatar.png')
                "
                alt=""
              />
            </div>
            <div class="item-c">
              <div class="item-cl">2</div>
              <div class="item-cr">
                <span>
                  {{
                    listData2[1] && listData2[1].associationName
                      ? listData2[1].associationName
                      : "暂无"
                  }}
                </span>
              </div>
            </div>
            <div class="item-b">
              <span>
                积分:{{
                  listData2[1] && listData2[1].totalPointCount
                    ? listData2[1].totalPointCount
                    : "0"
                }}
              </span>
            </div>
          </div>
          <div class="item te">
            <div class="item-t">
              <img
                :src="
                  listData2[0] && listData2[0].picture
                    ? listData2[0].picture
                    : require('@/assets/img/default_avatar.png')
                "
                alt=""
              />
            </div>
            <div class="item-c">
              <div class="item-cl">1</div>
              <div class="item-cr">
                <span>
                  {{
                    listData2[0] && listData2[0].associationName
                      ? listData2[0].associationName
                      : "暂无"
                  }}
                </span>
              </div>
            </div>
            <div class="item-b">
              <span>
                积分:{{
                  listData2[0] && listData2[0].totalPointCount
                    ? listData2[0].totalPointCount
                    : "0"
                }}
              </span>
            </div>
          </div>
          <div class="item third">
            <div class="item-t">
              <img
                :src="
                  listData2[2] && listData2[2].picture
                    ? listData2[2].picture
                    : require('@/assets/img/default_avatar.png')
                "
                alt=""
              />
            </div>
            <div class="item-c">
              <div class="item-cl">3</div>
              <div class="item-cr">
                <span>
                  {{
                    listData2[2] && listData2[2].associationName
                      ? listData2[2].associationName
                      : "暂无"
                  }}
                </span>
              </div>
            </div>
            <div class="item-b">
              <span>
                积分:{{
                  listData2[2] && listData2[2].totalPointCount
                    ? listData2[2].totalPointCount
                    : "0"
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="list">
          <v-list
            :finished="finished2"
            @onLoad="onLoad2"
            :finishedText="finishedText2"
            ref="list2"
          >
            <div class="item" v-for="(item, index) in listData2" :key="index">
              <div class="oItem" v-if="index != 0 && index != 1 && index != 2">
                <div class="item-l">
                  <div class="indexs">{{ index + 1 }}</div>
                  <div class="img">
                    <img
                      :src="
                        item.picture
                          ? item.picture
                          : require('@/assets/img/default_avatar.png')
                      "
                      alt=""
                    />
                  </div>
                  <div class="name">{{ item.associationName }}</div>
                </div>
                <div class="item-r">
                  <div class="pointNum">{{ item.totalPointCount }}分</div>
                  <div class="pointImg">
                    <img src="./img/point.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pointRankListUrl, pointsUrl, getScoreList } from "./api.js";
import { getHashParam, handleImg } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "pointRankList",
  data() {
    return {
      flag: "1",
      finishedText: "没有更多了",
      finished: false,
      finishedText2: "没有更多了",
      finished2: false,
      form: {},
      pointsData: {},
      listData: [],
      listData2: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      requestData2: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.flag = this.$route.query.id;
  },
  updated() {
    // 切换不同模块时触发
    this.$nextTick(() => {
      if (this.$refs.scollElement) {
        // 滚动元素跳转到顶部
        this.$refs.scollElement.scrollTop = 0;
      }
    });
  },
  mounted() {
    this.getPoints();
    this.getCommunityList();
  },
  methods: {
    personal(str) {
      this.flag = str;
    },
    community(str) {
      this.flag = str;
    },
    onLoad() {
      //个人积分
      this.getPointsList();
    },
    onLoad2() {
      //社群积分
      this.getCommunityList();
    },
    getPoints() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios
        .post(`${pointsUrl}`, this.$qs.stringify(params), {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data) {
              this.pointsData = res.data;
              if (this.pointsData.avatar) {
                this.pointsData.avatar = handleImg(
                  104,
                  104,
                  this.pointsData.avatar
                );
              }
            }
          } else {
          }
        });
    },
    getCommunityList() {
      //社群积分
      let params = {
        curPage: this.requestData2.curPage++,
        pageSize: this.requestData2.pageSize,
        tenantId: this.tenantId,
        associationId: "",
        associationLevel: 1,
        parentId: 1,
        // curPage: null,
        // pageSize: null,
        // tenantId: null,
        // associationId:null,
        // associationLevel:null,
        // parentId:null,
      };
      this.$axios
        .get(`${getScoreList}`, params, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished2 = true;
            } else {
              this.$refs.list2._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText2 = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.picture) {
                item.picture = handleImg(96, 96, item.picture);
              }
              this.listData2.push(item);
            });
          } else {
          }
        });
    },
    getPointsList() {
      //个人积分
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        tenantId: this.tenantId,
      };
      this.$axios
        .get(
          `${pointRankListUrl}`,
          { params },
          {
            headers: { "Content-Type": "application/json; charset=UTF-8" },
          }
        )
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.avatar) {
                item.avatar = handleImg(96, 96, item.avatar);
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    toList() {
      this.$router.push({
        name: "pointsList",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.pointRankList {
  height: 100vh;
  background: rgba(201, 227, 255, 1);
  padding: 0 32px 0;
  box-sizing: border-box;
  .box_top {
    width: 100%;
    height: 310px;
    padding: 32px;
    padding-bottom: 0;
    box-sizing: border-box;
    background: rgba(201, 227, 255, 1);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    .in_box {
      height: 100%;
      background: url("./img/bg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      .tabs {
        position: absolute;
        top: 170px;
        left: 50%;
        transform: translateX(-50%);
        width: 480px;
        height: 76px;
        background: #c9e3ff;
        border-radius: 52px;
        .personal {
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          height: 100%;
          text-align: center;
          line-height: 76px;
          font-size: 40px;
          font-weight: 600;
          color: #000;
        }
        .community {
          margin-left: 50%;
          width: 50%;
          height: 100%;
          text-align: center;
          line-height: 76px;
          font-size: 40px;
          font-weight: 600;
          color: #000;
        }
        .Select1 {
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          height: 100%;
          text-align: center;
          line-height: 76px;
          font-size: 40px;
          font-weight: 600;
          color: #fff;
          border-radius: 52px;
          background: #a7d2ff;
        }
        .Select2 {
          margin-left: 50%;
          width: 50%;
          height: 100%;
          text-align: center;
          line-height: 76px;
          font-size: 40px;
          font-weight: 600;
          color: #fff;
          border-radius: 52px;
          background: #a7d2ff;
        }
      }
    }
  }
  .pointRankList-t {
    background: rgba(201, 227, 255, 1);
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 208px;
    padding: 32px;
    box-sizing: border-box;
    .body {
      background: url("./img/topBack.png") no-repeat;
      background-size: 100% 100%;
      padding: 32px 20px;
      height: 208px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      color: #fff;
      .rank {
        text-align: center;
        .rank-t {
          font-size: 64px;
          line-height: 90px;
        }
        .rank-b {
          font-size: 28px;
          line-height: 40px;
        }
      }
      .head {
        display: flex;
        align-items: center;
        .head-l {
          width: 104px;
          height: 104px;
          border-radius: 50%;
          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
          }
        }
        .head-r {
          width: 260px;
          font-size: 32px;
          line-height: 44px;
          margin-left: 10px;
        }
      }
      .point {
        display: flex;
        align-items: center;
        .point-l {
          font-size: 32px;
          line-height: 44px;
          margin-right: 8px;
        }
        .point-r {
          width: 40px;
          height: 40px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .pointRankList-b {
    padding: 0px 18px;
    height: 100%;
    .body {
      padding: 350px 18px;
      padding-bottom: 0;
      background: rgba(241, 248, 255, 1);
      overflow: scroll;
      height: 100%;
      box-sizing: border-box;
      .topRank {
        display: flex;
        height: 280px;
        background: #fff;
        .item {
          flex: 0.333;
          padding: 32px 16px 0;
          box-sizing: border-box;
          background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
          box-shadow: 0px -10px 12px 0px rgba(104, 122, 15, 0.12);
          border-radius: 16px;
          .item-t {
            width: 104px;
            height: 120px;
            margin: 0 auto;
            text-align: center;
            img {
              width: 76px;
              height: 76px;
              border-radius: 50%;
              vertical-align: middle;
            }
            &::after {
              content: "";
              width: 0;
              height: 100%;
              display: inline-block;
              vertical-align: middle;
            }
          }
          .item-c {
            display: flex;
            margin: 32px 0 10px;
            justify-content: center;
            .item-cl {
              width: 40px;
              height: 40px;
              text-align: center;
              background: rgba(255, 91, 90, 1);
              font-size: 24px;
              line-height: 40px;
              color: #fff;
              border-radius: 50%;
              margin-right: -20px;
              z-index: 99;
            }
            .item-cr {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 148px;
              box-sizing: border-box;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              height: 40px;
              background: rgba(253, 214, 0, 1);
              color: rgba(102, 102, 102, 1);
              font-size: 24px;
              border-radius: 0 200px 200px 0;
              span {
                // line-height: 1;
                vertical-align: middle;
              }
            }
          }
          .item-b {
            text-align: center;
            font-size: 24px;
            color: #666;
          }
        }
        .te {
          margin-top: -38px;
          z-index: 99;
          .item-t {
            background: url("./img/one.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        .second {
          .item-t {
            width: 92px;
            height: 104px;
            background: url("./img/second.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        .third {
          .item-t {
            width: 92px;
            height: 104px;
            background: url("./img/third.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      .list {
        padding: 0 16px;
        .item {
          .oItem {
            display: flex;
            margin-top: 28px;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 64px;
            .item-l {
              display: flex;
              align-items: center;
              .indexs {
                font-size: 32px;
                color: rgba(102, 102, 102, 1);
              }
              .img {
                width: 64px;
                height: 64px;
                border: 1px solid rgba(0, 126, 255, 1);
                margin: 0 16px;
                border-radius: 50%;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }
              .name {
                width: 120px;
                text-overflow: ellipsis;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 28px;
                color: rgba(102, 102, 102, 1);
              }
            }
            .item-r {
              display: flex;
              align-items: center;
              .pointNum {
                font-size: 32px;
                color: rgba(51, 51, 51, 1);
                margin-right: 8px;
              }
              .pointImg {
                width: 40px;
                height: 40px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.pointRankList {
}
</style>
